<script lang="ts">
  import './game-board.less';
  import { playerInstance, enemyInstance, dieHistory, actionHistory, techniqueHistory, lockedDie, glossary, activePlayer, inactivePlayer, options, screens } from '../store';
  import type {Die as DieType} from './models/die.type';
  import HealthBar from "./components/health-bar.svelte";
  import HistoryBar from "./components/history-bar.svelte";
  import Particles from "./components/particle.svelte";
  import Portrait from "./components/portrait.svelte";
  import DiceTray from "./dice-tray/dice-tray.svelte";
  import Die from "./dice-tray/die.svelte";
  import DieBlank from "./dice-tray/die-blank.svelte";
  import Icon from "./icon/icon.svelte";
  import { createDie } from "./models/die.type";
  import { filterTechniquesByTag, filterTechniques, validateTechnique, isTechniqueValid, type Technique } from "./models/techniques.type";
  import type { Modifier } from "./models/modifier";
  import Hover from './components/hover.svelte';
  import Text from './components/text.svelte';
  import Draggable from './components/draggable.svelte';
  import { addModifier, getModifiers, type Player } from './models/player.type';
  import type { Battle } from './models/battle.type';
  import { openOptions } from './modals/modals';
  import { onMount } from 'svelte';
  import { continueTutorial } from './utils/tutorial';

  import { fade } from 'svelte/transition';
  import { flip } from 'svelte/animate';
  import { send, receive, squish } from '../animation';

  import { randomFloat, clamp, distance } from './utils/math';

  import { calculateMazlovs, chooseTechnique } from './utils/ai';
  import { DieFace } from './models/face.type';

  export let data: {
    player: Player,
    enemy: Player,
    battle: Battle,
  };

  let isPlayerActive = true;
  let gameState = 'prebattle';
  setTimeout(startTurn, 100);
  let positions = Array<Array<number>>;
  let lockedDieUI = [];
  let techniqueHistoryUI = [];

  playerInstance.subscribe(() => {
    if(isPlayerActive){
      activePlayer.set($playerInstance);
      inactivePlayer.set($enemyInstance);
    }
    else{
      activePlayer.set($enemyInstance);
      inactivePlayer.set($playerInstance);
    }
  });
  enemyInstance.subscribe(() => {
    if(isPlayerActive){
      activePlayer.set($playerInstance);
      inactivePlayer.set($enemyInstance);
    }
    else{
      activePlayer.set($enemyInstance);
      inactivePlayer.set($playerInstance);
    }
  });
  playerInstance.set(data.player);
  enemyInstance.set(data.enemy);
  activePlayer.set($playerInstance);
  inactivePlayer.set($enemyInstance);

  let playerDie: Array<DieType> = $playerInstance.dice.map((die: string, index: number) => {
    return createDie(die, index, 'p'+index);
  });

  let enemyDie: Array<DieType> = $enemyInstance.dice.map((die: string, index: number) => {
    return createDie(die, index, 'e'+index);
  });
  let activeDie: Array<DieType> = [];
  let tempLocks: Array<DieType> = [];
  let dieElements = [];
  let diceContainer: HTMLDivElement;

  let validTechniques: Array<Technique> = [];

  let rerollMax: number;
  let rerollRemaining: number;

  techniqueHistory.length = 0;

  function openGlossary(){
    glossary.open.set(true);
  }

  function updateDice(){
    lockedDieUI = lockedDie;
    activeDie = activeDie;
  }
  window.addEventListener('updateDice', updateDice);

  function updatePlayers(){
    $enemyInstance = $enemyInstance;
    $playerInstance = $playerInstance;

    checkDied();
  }
  window.addEventListener('updatePlayers', updatePlayers);

  function lockDie(die: DieType){
    // die.locked = true;
    if(!lockedDie.includes(die)){
      lockedDie.push(die);
      activeDie.splice(activeDie.indexOf(die), 1);
      updateDice();
    }
  }

  function unlockDie(die: DieType){
    // prevent unlocking broken die
    if(die.value()[0] === DieFace.Broken){
      return;
    }
    // die.locked = false;
    activeDie.push(die);
    lockedDie.splice(lockedDie.indexOf(die), 1);
    updateDice();
  }

  function clickDie(die: DieType){
    /* let die = activeDie.find(die => die.id == dieIn.id);
    if(!die){
      die = lockedDie.find(die => die.id == dieIn.id);
    }
    if(!die){
      console.error('Die not found', dieIn, activeDie, lockedDie);
      return;
    } */

    switch(gameState){
      case 'rolling':
        unlockDie(die);
        break;

      case 'actionPhase':
        if(!die.used){
          die.selected = !die.selected;
          updateValidTechniques();
          updateDice();
        }
        break;
    }
  }

  function roll(){
    let proms = [];
    dieElements.forEach((die, index) => {
      if(die && die.roll && activeDie.includes(die.die)){
        proms.push(die.roll());
      }
    });
    moveDie(dieElements.length);

    getModifiers($activePlayer, 'rollStart').forEach(mod => {
      mod.rollStart(dieElements, activeDie, rerollMax-rerollRemaining);
    });

    return Promise.all(proms)
    .then((res) => {
      res.forEach((result, index) => {
        if(result === false){
          lockDie(activeDie[index]);
        }
      });

      let proms2 = [];

      getModifiers($activePlayer, 'rollMid').forEach(mod => {
        mod.rollMid(dieElements, activeDie, proms2);
      });

      return Promise.all(proms2);
    })
    .then(() => {
      handleInstant(activeDie);

      getModifiers($activePlayer, 'rollEnd').forEach(mod => {
        mod.rollEnd(dieElements, activeDie);
      });

      updatePlayers();
      updateDice();
      checkDied();

      if(rerollRemaining <= 0){
        lockin();
      }
    });
  }

  function reroll(){
    if(rerollRemaining <= 0){
      return;
    }

    let rolling = false;
    dieElements.forEach(dieElement => {
      if(dieElement && dieElement.state == 'rolling'){
        rolling = true;
      }
    });
    if(rolling){
      return;
    }

    rerollRemaining --;

    return roll();
  }

  function lockin(){
    tempLocks = [...activeDie];
    for(var i = activeDie.length-1; i >= 0; i--){
      lockDie(activeDie[i]);
    }
    setState('actionPhase');
    updateValidTechniques();
    updateDice();

    $dieHistory = lockedDie.map(die => die.clone());
    dieHistory.clear();
  }

  function handleInstant(dice: Array<DieType>){
    // filter to unlocked die
    dice.forEach(async (die) => {

      var techniques = filterTechniques([die]);
      getModifiers($activePlayer, 'getTechniques').forEach(mod => {
        mod.getTechniques(techniques).map(technique => {
          techniques.push(technique);
        });
      });

      var technique = techniques.filter(technique => {
        return technique.tags.includes('instant');
      }).find(technique => {
        var ingredients = die.value();
        return technique.ingredients.toString() == ingredients.toString();
      });

      if(!technique){
        return;
      }

      var able = true;
      getModifiers($activePlayer, 'canUseTechnique').forEach(mod => {
        var result = mod.canUseTechnique(technique, $activePlayer, $inactivePlayer);
        if(result && result.able === false){
          able = false;
        }
      });
      getModifiers($inactivePlayer, 'canInterrupt').forEach(mod => {
        var result = mod.canInterrupt(technique, $activePlayer, $inactivePlayer);
        if(result && result.able === false){
          able = false;
        }
      });

      if(able){
        // Awaiting on the array
        const uses: Array<Modifier> = getModifiers($activePlayer, 'useTechnique');
        for(let use of uses){
          await use.useTechnique(technique, $inactivePlayer, $activePlayer, [die]);
        };

        await technique.activate($inactivePlayer, $activePlayer, die);
      }

      addHistory(technique);

      updateDice();
      updatePlayers();
      checkDied();
    });
  }

  function selectIngredients(technique: Technique){
    var selectedDie = lockedDie.filter(die => die.selected);
    var spareDie = lockedDie.filter(die => !die.selected && !die.used);
    var dice = isTechniqueValid(selectedDie, spareDie, technique);

    if(dice){
      dice.forEach(die => {
        die.selected = true;
      });
    }
    updateValidTechniques();
    updateDice();
  }

  async function execute(technique){
    enemyInstance.put({...$enemyInstance});
    playerInstance.put({...$playerInstance});

    // Only allow recipes that are valid
    if(!technique.valid){
      return;
    }

    // Select all required die
    var selectedDie = lockedDie.filter(die => die.selected);
    var spareDie = lockedDie.filter(die => !die.selected && !die.used);
    var die = isTechniqueValid(selectedDie, spareDie, technique);

    if(die){
      die.forEach(element => {
        element.selected = false;
        element.used = true;
      });
    }

    // sort used die to the bottom
    lockedDie.sort((a, b) => {
      if(a.used){
        return 1;
      }
      return -1;
    });
    updateDice();
    technique.dice = [];

    var able = true;
    getModifiers($activePlayer, 'canUseTechnique').forEach(mod => {
      var result = mod.canUseTechnique(technique, $activePlayer, $inactivePlayer);
      if(result && result.able === false){
        able = false;
      }
    });
    getModifiers($inactivePlayer, 'canInterrupt').forEach(mod => {
      var result = mod.canInterrupt(technique, $activePlayer, $inactivePlayer);
      if(result && result.able === false){
        able = false;
      }
    });

    if(able){
      // Awaiting on the array

      const uses =  getModifiers($activePlayer, 'useTechnique');
      for(let use of uses){
        use.useTechnique(technique, $inactivePlayer, $activePlayer, die||[]);
      }

      await technique.activate($inactivePlayer, $activePlayer, die);
    }

    dieHistory.put(lockedDie.map(die => die.clone()));
    updateDice();

    addHistory(technique);

    checkDied();
    updateValidTechniques();

    continueTutorial({type: 'techniqueUsed', value: true});
  }

  function checkDied(){
    getModifiers($enemyInstance, 'checkDied').forEach(mod => {
      mod.checkDied();
    });

    getModifiers($playerInstance, 'checkDied').forEach(mod => {
      mod.checkDied();
    });

    // Check health
    if($playerInstance.healthCurrent <= 0 && !$options.invincible){
      endGame('loss');
    }
    if($enemyInstance.healthCurrent <= 0){
      endGame('win');
    }

    // updatePlayers();
  }

  function undo(){
    if(playerInstance.count() <= 0){
      setState('rolling');
      for(var i = tempLocks.length-1; i >= 0; i--){
        const die = lockedDie.find(die => {
          return die.index == tempLocks[i].index;
        });
        if(die){
          unlockDie(die);
        }
      }

      return;
    }
    //reset player states
    playerInstance.undo();
    enemyInstance.undo();

    // reset die
    dieHistory.undo();
    lockedDie.length = 0;
    $dieHistory.forEach((die, index) => lockedDie[index] = die.clone());
    techniqueHistory.shift();

    techniqueHistoryUI = techniqueHistory;

    // reset recipes
    updateValidTechniques();
    updateDice();
  }

  function setState(newState){
    gameState = newState;
    continueTutorial({type: 'battleStateChange', value: newState});

    switch(gameState){
      case 'actionPhase':
        getModifiers($activePlayer, 'actionPhaseStart').forEach(mod => {
          mod.actionPhaseStart();
        });
        break;
    }
  }

  function endTurn(){
    getModifiers($activePlayer, 'endTurn').forEach(mod => {
      mod.endTurn();
    });
    checkDied();

    // swap player
    isPlayerActive = !isPlayerActive;
    if(isPlayerActive){
      activePlayer.set($playerInstance);
      inactivePlayer.set($enemyInstance);
    }
    else{
      activePlayer.set($enemyInstance);
      inactivePlayer.set($playerInstance);
    }

    updatePlayers();
    startTurn();
  }

  function startTurn(){
    setState('rolling');
    playerInstance.clear();
    enemyInstance.clear();
    dieHistory.clear();

    // addModifier($playerInstance, 'slow', 5);
    // reset dice
    activeDie = $activePlayer.dice.map((die: string, index: number) => {
      return createDie(die, index, 'p'+index);
    });
    getModifiers($activePlayer, 'getDice').forEach(mod => {
      mod.getDice(activeDie).forEach(die => {
        if(die){
          activeDie.push(createDie(die, (activeDie.length), 'p'+(activeDie.length)));
        }
      });
    });

    moveDie(activeDie.length, true);

    lockedDie.length = 0;

    // reset rerolls
    rerollMax = $activePlayer.rerollMax;

    getModifiers($activePlayer, 'beforeStartTurn').forEach(mod => {
      mod.beforeStartTurn();
    });
    getModifiers($activePlayer, 'startTurn').forEach(mod => {
      let res = mod.startTurn({activeDie, rerollMax, activePlayer: $activePlayer, inactivePlayer: $inactivePlayer});

      if(res){
        if(res.activeDie){
          activeDie = res.activeDie;
        }
        if(res.rerollMax){
          rerollMax = res.rerollMax;
        }
      }
    });

    rerollRemaining = rerollMax;

    addHistory({
      title: 'Change Turns',
      text: '',
      ingredients: ['swap'],
    });

    setTimeout(()=>{
      roll();

      if(!isPlayerActive || $options.autoPlay){
        setTimeout(AI, 1000);
      }
    },500);
  }

  function endGame(gameState: string){
    if(gameState == 'win'){
      data.battle.outcome = 'win';
      setState('win');
    }
    else{
      data.battle.outcome = 'lose';
      setState('lose');
    }

    if(actionHistory.length){
      addHistory({
        title: 'End Game',
        text: '',
        ingredients: ['grave'],
      });
    }
  }

  function addHistory(data){
    techniqueHistory.unshift({
      player: $activePlayer,
      title: data.title,
      text: data.text,
      ingredients: data.ingredients,
      tags: data.tags,
      actions: [...actionHistory]
    });

    actionHistory.length = 0;

    techniqueHistoryUI = techniqueHistory;
  }

  function advance(){
    screens.set('battleover', data);
  }
  function restart(){
    screens.clear();
  }

  let lastWidth = 0;
  let lastHeight = 0;
  function moveDie(count: number, reset: boolean = false){
    if(!diceContainer || !(diceContainer.clientWidth || lastWidth)){
      setTimeout(() => {
        moveDie(count, reset);
      }, 100);

      for(let i = 0; i < count; i++){
        positions[i] = new Array(4).fill(randomFloat(10, 80));
      }
      return;
    }

    for(let i = 0; i < count; i++){
      if(reset){
        positions[i] = [randomFloat(10, 80) , randomFloat(10, 80)];
      }
      else{
        positions[i][0] = positions[i][0] + randomFloat(-15, 15);
        positions[i][1] = positions[i][1] + randomFloat(-15, 15);

        // Bind to screen
        positions[i][0] = clamp(positions[i][0], 10, 80);
        positions[i][1] = clamp(positions[i][1], 10, 80);
      }

      positions[i][2] = positions[i][0];
      positions[i][3] = positions[i][1];

      if(lastWidth && lastHeight){
        positions[i][2] = positions[i][0] * lastWidth / 100;
        positions[i][3] = positions[i][1] * lastHeight / 100;
      }
      else if(diceContainer && diceContainer.clientWidth && diceContainer.clientHeight){
        lastWidth = diceContainer.clientWidth;
        lastHeight = diceContainer.clientHeight;
        positions[i][2] = positions[i][0] * lastWidth / 100;
        positions[i][3] = positions[i][1] * lastHeight / 100;
      }

      // test position against previous positions for distance
      for(let j = 0; j < i; j++){
        let dist = distance(positions[i][0], positions[i][1], positions[j][0], positions[j][1])
        // let dist = Math.sqrt(Math.pow(positions[i][0]-positions[j][0], 2) + Math.pow(positions[i][1]-positions[j][1], 2));
        if(dist < 20){
          i --;
          continue;
        }
      }
    }
  }

  function resize(){
    lastWidth = diceContainer.clientWidth;
    lastHeight = diceContainer.clientHeight;
    moveDie(activeDie.length);
  }
  onMount(() => {
		window.addEventListener('resize', resize);

		return () => {
			window.removeEventListener('resize', resize);
		}
	});

  function updateValidTechniques(){
    validTechniques = filterTechniques(lockedDie);

    getModifiers($activePlayer, 'getTechniques').forEach(mod => {
      mod.getTechniques(validTechniques).map(technique => {
        validTechniques.push(technique);
      });
    });

    validTechniques.forEach(technique => {
      validateTechnique(technique, lockedDie);
    });

    // Sorts techniques by possibility
    validTechniques = validTechniques.sort((a, b) => {
      const aPossible = a.valid;
      const bPossible = b.valid;
      if(aPossible && bPossible){
        return 0;
      }
      if(aPossible){
        return -1;
      }
      return 1;
    });

    return validTechniques;
  }

  function AI(){
    switch(gameState){
      case 'rolling':
        if(!activeDie.length || rerollRemaining == 0){
          lockin();
          break;
        }
        var locked = false;
        for(var i = activeDie.length-1; i >= 0; i--){
          var target = 4;
          if(rerollRemaining <= 1){
            target = 2;
          }
          if(activeDie[i].value()[0] !== DieFace.Blank && activeDie[i].value()[0] !== DieFace.Broken){
            if(activeDie[i].activeFace >= target){
              lockDie(activeDie[i]);
              locked = true;
            }
          }
        }
        if(!locked){
          reroll()
          .then(() => {
            AI();
          });
          return;
        }
        break;

      case 'actionPhase':
        // reroll();
        let techniques = updateValidTechniques().filter(recipe => recipe.valid);

        if(techniques.length){
          const mazlovs = calculateMazlovs($activePlayer, $inactivePlayer);
          const technique = chooseTechnique(techniques, mazlovs);
          if(!technique){
            endTurn();
            return;
          }
          execute(technique);
        }
        else{
          endTurn();
          return;
        }
        break;
    }

    setTimeout(AI, 1000);
  }

  /* const viewTechniques = () => {
    glossary.open.set(true);
    glossary.selected.set('techniques');
    const allDie = [...activeDie, ...lockedDie];
    glossary.searchDie.set(allDie.map(die => die));
  }; */
</script>

<div class='layout'>
  <div class='column player'>
    <div class='tray'>
      {#if playerDie.length}
        <DiceTray
          clickEvent={clickDie}
          dice={isPlayerActive ? lockedDieUI : playerDie}
        />
      {/if}

      <HealthBar data={$playerInstance}/>

      <!-- <button class='btn' on:click={()=>{endGame('win');}}>
        Autowin
      </button>
      <button class='btn' on:click={()=>{endGame('lose');}}>
        Autolose
      </button> -->
    </div>

    <Portrait data={$playerInstance}/>
  </div>

  <div class='column board background {data.battle.environment}'>
    <HistoryBar history={techniqueHistoryUI}/>

    <!-- {#if gameState == 'rolling'} -->
    <div class='dice-container {gameState == "rolling" ? "" : "hidden"}' bind:this="{diceContainer}">
      {#each activeDie as die, index (die.id)}
      <Draggable
        left={positions[die.index][2]}
        top={positions[die.index][3]}
      >
        <div
          class='slot slot-{die.index}'
          in:receive="{{key: die.id}}"
          out:send="{{key: die.id}}"
        >
          <Die
            die={die}
            clickEvent={isPlayerActive ? () => {lockDie(die)} : ()=>{} }
            index={index}
            bind:this="{dieElements[die.index]}"
            hover={true}
          />
        </div>
      </Draggable>
      {/each}
    </div>
    <!-- {/if} -->

    {#if gameState == 'rolling'}
    <div class='actions actions-3'>
      <div class='action btn reroll' on:click='{isPlayerActive ? reroll : ()=>{}}' on:keydown={()=>{}}>
        <Icon name='reroll'/>

        <div class='sub'>
          {rerollRemaining}
        </div>
      </div>

      <div class='center-flex' style="align-items:end; grid-gap: 10px">
        <div class='action small btn' on:click={()=>{openOptions({})}} on:keydown={()=>{}}>
          <Icon name='options'/>
        </div>
        <div class='action small btn' on:click={openGlossary} on:keydown={()=>{}}>
          <Icon name='glossary'/>
        </div>
      </div>

      <button class='action btn lockin' on:click='{isPlayerActive ? lockin : ()=>{}}'>
        <Icon name="clash"/>
      </button>
    </div>
    {/if}

    {#if gameState == 'actionPhase'}
    <div class='skills'>
      {#each validTechniques as technique, index (technique.title)}
        <div class='technique {technique.valid ? "valid" : "invalid"} {technique.hidden ? "invisible" : ""}'
          animate:flip={{duration:400}}
        >
        <!-- in:fade={{duration:200}}
        out:squish={{duration:200}} -->
          <!-- intro:true -->
          <div class='ingredients' on:click={()=>{selectIngredients(technique)}} on:keydown={()=>{}}>
            {#each technique.ingredients as text, index}

              <DieBlank dieType={text} class=''>
                {#if technique.selected[index] && technique.selected[index] !== 'found'}
                  <Die die={technique.selected[index]} index={index} die3D={false} hover={false}/>
                {/if}
              </DieBlank>
              <!-- {#if technique.selected[index] == 'found'} -->
              <!-- {/if} -->


              <!-- {#if !technique.selected[index]}
                <DieBlank dieType={text} class='fade'/>
              {/if} -->
            {/each}

            {#if technique.requirements}
              {#each technique.requirements as requirement, index}
                {#if requirement.icon}
                  <div class='requirement {requirement.test($activePlayer, $inactivePlayer) ? "passed":""}'>
                    <Icon name={requirement.icon} width='40px' height='40px'/>
                    {#if requirement.iconBonus}
                      <Icon name={requirement.iconBonus} width='60px' height='60px'/>
                    {/if}

                    <Hover>
                      <div class='hover-icon'>
                        <Icon name={requirement.icon} width='40px' height='40px'/>
                        {#if requirement.iconBonus}
                          <Icon name={requirement.iconBonus} width='60px' height='60px'/>
                        {/if}
                      </div>
                      <br/>

                      <p>{requirement.text}</p>
                    </Hover>
                  </div>
                {/if}
              {/each}
            {/if}
          </div>
          <div class='description'>
            <h2>
              {technique.title}
            </h2>
            <Text data={technique.text}/>
          </div>
          {#if technique.valid}
          <button class='btn' on:click='{isPlayerActive ? ()=>{execute(technique)} : ()=>{}}'>
            <Icon name="redo" />
          </button>
          {/if}
        </div>
      {/each}
    </div>

    <div class='actions actions-3'>
      <button class='action btn undo' on:click={undo}>
        <Icon name="undo" width='40px' height='40px'/>
      </button>
      <div></div>
      <button class='action btn endturn' on:click='{isPlayerActive ? endTurn : ()=>{}}'>
        <Icon name="swap" width='40px' height='40px'/>
      </button>
    </div>
    {/if}

    <div class='center-flex {gameState == "win" ? "visible" : "hidden"}' style='flex-direction: column;'>
      <h1>You Win!</h1>

      <button class='continue btn' on:click={advance}>
        Continue
      </button>
    </div>

    <div class='center-flex {gameState == "lose" ? "visible" : "hidden"}' style='flex-direction: column;'>
      <h1>You Lose!</h1>

      <button class='continue btn' on:click={advance}>
        Continue
      </button>
    </div>

  </div>

  <div class='column enemy'>
    <Portrait data={$enemyInstance}/>

    <div class='tray'>
      <HealthBar data={$enemyInstance} reverse={true}/>

      {#if enemyDie.length}
        <DiceTray
          clickEvent={()=>{}}
          dice={!isPlayerActive ? lockedDieUI : enemyDie}
        />
      {/if}
    </div>
  </div>
</div>

<Particles/>