<script lang='ts'>
  import Die from './die.svelte';
  import { send, receive } from '../../animation';
  import { flip } from 'svelte/animate';

  export let clickEvent = (die:any) => {};
  export let dice;
  export { clazz as class };

  let clazz = '';
  let slots = 16;
  $: if (dice !== '-1' && dice) {
    slots = 16;
  }

  /*function updateDice(){
    dice = dice;
  }
  window.addEventListener('updateDice', updateDice);*/

  $: if (dice !== undefined) {
    //check if the dice have a duplicate id
    let ids = dice.map(die => die.id);
    let duplicates = ids.filter((id, index) => ids.indexOf(id) != index);
    if(duplicates.length > 0){
      console.error('Duplicate dice ids found: ', dice);
    }
  }
</script>

<div class='container'>
  <div class='slots dice-tray {clazz || ""}'>
    {#each Array(slots) as tick, index}
      <div class='slot'>
      </div>
    {/each}
  </div>

  <div class='dice-tray {clazz || ""}'>
    {#each dice as die, index (die.id)}
      <div
        class='die-container'
        in:receive="{{key: die.id}}"
        out:send="{{key: die.id}}"
      >
        <Die die={die} index={index} clickEvent={clickEvent} die3D={false} hover={true}/>
      </div>
    {/each}
  </div>
</div>

<style lang='less'>
  @import "../../defines.less";

  .container{
    position: relative;
    @media @wide{
      overflow-y: scroll;
      overflow-x: hidden;
    }
    @media @tall{
      overflow-x: scroll;
    }

    .dice-tray{
      display: grid;
      grid-template-columns: repeat(2,calc(var(--dice-size) + var(--dice-gap)));
      grid-auto-rows: calc(var(--dice-size) + var(--dice-gap));
      padding: var(--dice-gap);

      @media @tall{
        grid-template-rows: repeat(2,calc(var(--dice-size) + var(--dice-gap)));
        grid-auto-columns: calc(var(--dice-size) + var(--dice-gap));
        grid-auto-flow: column;
        height: 100%;
        align-content: center;
        justify-content: start;
        padding: 0 var(--dice-gap);
      }

      grid-gap: var(--dice-gap);
      justify-content: center;
      z-index: 1;
      position: relative;

      &.horizontal{
        grid-template-columns: repeat(6,calc(var(--dice-size) + var(--dice-gap)));
      }
    }

    .slots{
      position: absolute;

      @media @wide{
        left: 0;
        right: 0;
      }
    }
    .die-container{
      padding: var(--dice-gap-half);
    }
    .slot{
      border-radius: 4px;
      height: calc(var(--dice-size) + var(--dice-gap));
      width: calc(var(--dice-size) + var(--dice-gap));
      background: rgba(0,0,0,0.25);
      // background: var(--background-colour);
      // box-shadow: inset var(--background-colour-dark) var(--dice-gap-half) var(--dice-gap-half) var(--dice-gap);
      background-image: url('../../assets/die.png');
      background-size: 108%;
      transform: rotate(180deg);
      background-position: center;
      // outline: var(--background-colour-dark) 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>