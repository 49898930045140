import type { traitDefinition } from '../trait.type';
import { type Player, dealDamage, addModifier } from "../player.type";
import { invokeDie } from "../die.type";
import { Trait } from '../trait.type';
import type { Technique } from '../techniques.type';
import { randomInt } from '../../utils/math';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { DieFace } from '../face.type';
import type { ModifierParams } from '../modifier';
import { AmmoType } from '../techniques/ammo';
import type { Song } from '../status-effects/song';
import { StatusEffect } from '../status-effects';

export const player: Array<traitDefinition> = [
  {
    type: 'knight',
    title: 'Knight\'s Armour',
    text: 'Retain up to 5 block at the start of your turn.',//20% of max HP
    icon: 'trait',
    class: class extends Trait {
      init(): void {
        this.priority = 1;
      }

      startTurn(): void {
        const block = this.owner.statusEffects.find(modifier => modifier.type === 'block');
        if (block) {
          // override block's startTurn function
          block.startTurn = () => {
            // get the value of block.stacks over 5
            let stacks = Math.max(0, block.stacks - 5);
            block.removeStacks(stacks);
          };
        }
      }
    }
  }, {
    type: 'barbarian',
    title: 'Barbarian\'s Rage',
    text: 'Gain 1 Regeneration for every instance of damage taken, but remove all Regeneration at the start of your turn.',
    icon: 'trait',
    class: class extends Trait {
      init(): void {
        this.priority = 3;
      }

      damageIn(): void {
        addModifier(this.owner, StatusEffect.Regen, 1);
      }

      startTurn({ activeDie, rerollMax, activePlayer, inactivePlayer }): { activeDie: Array<Die>, rerollMax: number, activePlayer: Player, inactivePlayer: Player } | void {
        const regeneration = this.owner.statusEffects.find(modifier => modifier.type === 'regeneration');
        if (regeneration) {
          regeneration.removeStacks(regeneration.stacks);
        }
      }
    }
  }, {
    type: 'warrior',
    title: 'Warrior\'s Smash',
    text: 'If the enemy is Stunned, deal 1 extra damage.',
    icon: 'trait',
    class: class extends Trait {
      damageOut(damage: Damage): void {
        const stun = this.owner.statusEffects.find(modifier => modifier.type === 'stun');
        if (stun) {
          damage.value++;
        }
      }
    }
  }, {
    type: 'gladiator',
    title: 'Ludus Training',
    text: 'When ending the turn with a Stance active, gain 1 Powerful.',
    icon: 'trait',
    class: class extends Trait {
      endTurn(): void {
        const stance = this.owner.statusEffects.find(modifier => modifier.type === 'stance');
        if (stance) {
          addModifier(this.owner, StatusEffect.Powerful, 1);
        }
      }
    }
  }, {
    type: 'shadow-walker',
    title: 'Shadow Walker',
    text: 'When receiving damage while stealthed, reduce the damage to 0.',
    icon: 'trait',
    class: class extends Trait {
      init(): void {
        this.priority = 1;
      }

      damageIn(damage: Damage): void {
        const stealth = this.owner.statusEffects.find(modifier => modifier.type === 'stealth');
        if (stealth) {
          damage.value = 0;
          stealth.removeStacks(1);
        }
      }
    }
  }, {
    type: 'bladestorm',
    title: 'Bladestorm',
    text: 'Every third attack, invoke a shiv.',
    icon: 'trait',
    class: class extends Trait {
      init() {
        super.init();
        this.stacks = 0;
      }

      damageOut(damage: Damage): void {
        this.stacks++;
        if (this.stacks == 3) {
          invokeDie([DieFace.Shiv]);
          this.stacks = 0;
        }
      }
    }
  }, {
    type: 'fencer',
    title: 'Fencer\'s Riposte',
    text: 'When attacked for 0 damage, deal 1 damage.',
    icon: 'trait',
    class: class extends Trait {
      init() {
        this.priority = 5;
      }

      damageIn(damage: Damage): void {
        if (damage.value == 0) {
          dealDamage({ value: 1, target: damage.source, source: damage.target, dice: [] });
        }
      }
    }
  }, {
    type: 'spell-slinger',
    title: 'Infusing Imbuement',
    text: 'When gaining infusion, also gain 1 imbue of the same element.',
    icon: 'trait',
    class: class extends Trait {
      init() {
        this.priority = 5;
      }

      modifierAdd(type: string, stacks: number, params: any): number {
        if (type == 'infusion') {
          switch (params.infusion){
            case 'poison':
              addModifier(this.owner, StatusEffect.Imbue, 1, { imbue: StatusEffect.Poison });
              break;

            case 'blood':
              addModifier(this.owner, StatusEffect.Imbue, 1, { imbue: StatusEffect.Bleed });
              break;

            case 'fire':
              addModifier(this.owner, StatusEffect.Imbue, 1, { imbue: StatusEffect.Burning });
              break;

            case 'ice':
              addModifier(this.owner, StatusEffect.Imbue, 1, { imbue: StatusEffect.Freezing });
              break;

            case 'lightning':
              addModifier(this.owner, StatusEffect.Imbue, 1, { imbue: StatusEffect.Spark });
              break;

            case 'earth':
              addModifier(this.owner, StatusEffect.Imbue, 1, { imbue: StatusEffect.Brittle });
              break;
          }
        }
        return stacks;
      }
    }
  }, {
    type: 'anarchist',
    title: 'Elemental Anarchy',
    text: 'When suffering damage, gain infusion for the damage type.',
    icon: 'trait',
    class: class extends Trait {
      damageIn(damage: Damage): void {
        if (damage.source == damage.target) {
          var types = [DieFace.Fire, DieFace.Ice, DieFace.Lightning, DieFace.Earth];
          // see if damage.die contains one of type
          var die = damage.dice.find(die => {
            return types.some(type => die.value().includes(type));
          });
          if (die) {
            addModifier(damage.source, StatusEffect.Infusion, 1, { infusion: DieFace[die.value()[0]] });
          }
        }
      }
    }
  }, {
    type: 'mana-born',
    title: 'Mana Born',
    text: 'When using a technique with mana, invoke mana once per turn.',
    icon: 'trait',
    class: class extends Trait {
      init() {
        super.init();
        this.properties.manaInvoked = false;
      }

      startTurn({ activeDie, rerollMax, activePlayer, inactivePlayer }): { activeDie: Array<Die>, rerollMax: number, activePlayer: Player, inactivePlayer: Player } | void {
        this.properties.manaInvoked = false;
      }

      damageOut(damage: Damage): void {
        if (!this.properties.manaInvoked && damage.dice.find(die => die.value().includes(DieFace.Mana))) {
          this.properties.manaInvoked = true;
          invokeDie([DieFace.Mana]);
        }
      }
    }
  }, {
    type: 'wielder',
    title: 'Master of Elements',
    text: 'Gain access to elemental combination techniques.',
    icon: 'trait',
    class: class extends Trait {
      getTechniques(techniques: Technique[]): Technique[] {
        return [
          {
            ingredients: [DieFace.Fire, DieFace.Ice],
            title: 'Steam Cleaning',
            text: 'Removes 2 block and 2 ward. Apply 1 Vulnerable.',
            tags: ['damage', 'debuff'],
            activate: (target: Player, source: Player, dice: Array<Die>) => {
              const block = target.statusEffects.find(modifier => modifier.type === 'block');
              if (block) {
                block.removeStacks(2);
              }
              const ward = target.statusEffects.find(modifier => modifier.type === 'ward');
              if (ward) {
                ward.removeStacks(2);
              }
              addModifier(target, StatusEffect.Vulnerable, 1);
            }
          }, {
            ingredients: [DieFace.Fire, DieFace.Earth],
            title: 'Liquid Hot Magma',
            text: 'Apply 2 burning, Deal damage equal to burning.',
            tags: ['damage', 'debuff'],
            activate: (target: Player, source: Player, dice: Array<Die>) => {
              addModifier(target, StatusEffect.Burning, 2);
              const burning = target.statusEffects.find(modifier => modifier.type === 'burning');
              if (burning) {
                dealDamage({ value: burning.stacks, target, source, dice });
              }
            }
          }, {
            ingredients: [DieFace.Lightning, DieFace.Earth],
            title: 'Grounding Charge',
            text: 'Transfer a Bane from self to enemy.',
            tags: ['cleanse', 'debuff'],
            activate: (target: Player, source: Player, dice: Array<Die>) => {
              const bane = source.statusEffects.find(modifier => modifier.tags.includes('bane'));
              if (bane) {
                addModifier(target, bane.type as StatusEffect, bane.stacks);
                bane.removeStacks(bane.stacks);
              }
            }
          }, {
            ingredients: [DieFace.Lightning, DieFace.Ice],
            title: 'Violent Tempest',
            text: 'Deal 1 damage, Apply 1 Freezing, Apply 1 Spark, two times.',
            tags: [''],
            activate: (target: Player, source: Player, dice: Array<Die>) => {
              for (let i = 0; i < 2; i++) {
                dealDamage({ value: 1, target, source, dice });
                addModifier(target, StatusEffect.Freezing, 1);
                addModifier(target, StatusEffect.Spark, 1);
              }
            }
          }
        ];
      }
    }
  }, {
    type: 'dryad',
    title: 'Bark Skin',
    text: 'When gaining block, gain an equal amount of ward. When gaining ward, gain an equal amount of block.',
    icon: 'trait',
    class: class extends Trait {
      modifierAdd(type: string, stacks: number, params: ModifierParams): number {
        if (params && params.recursive === false){
          return stacks;
        }
        if (type == 'block') {
          addModifier(this.owner, StatusEffect.Ward, stacks, {recursive: false});
        }
        else if (type == 'ward') {
          addModifier(this.owner, StatusEffect.Block, stacks, {recursive: false});
        }
        return stacks;
      }
    }
  }, {
    type: 'archer',
    title: 'Quiver of Plenty',
    text: 'At the start of turn, if you have less than 2 ammo, gain up to 2 Ammo (Basic).',
    icon: 'trait',
    class: class extends Trait {
      startTurn(): void {
        const ammo = this.owner.statusEffects.find(modifier => modifier.type === 'ammo');
        if (ammo && ammo.stacks < 2) {
          ammo.update(2 - ammo.stacks, { ammoType: AmmoType.Blank });
        }
        else {
          addModifier(this.owner, StatusEffect.Ammo, 2, { ammoType: AmmoType.Blank });
        }
      }
    }
  }, {
    type: 'tamer',
    title: 'Wolfie',
    text: 'Gain bite & claw die based on character level.',
    icon: 'trait',
    class: class extends Trait {
      getDice(): Array<string> {
        var wolfDie = ['bite>', 'claws>'];
        var level = Math.max(1, Math.floor(this.owner.level/2));
        var dice = [];
        var index = 0;

        while(level > 0){
          var dieLevel = Math.max(0, Math.min(3, level - 3));
          dice.push(wolfDie[index] + dieLevel);
          index = (index + 1) % wolfDie.length;
          level -= 3;
        }

        return dice;
      }
    }
  }, {
    type: 'piper',
    title: 'Pied Piper',
    text: 'When using a technique that contains the blowgun, apply the effect of the current song once per turn.',
    icon: 'trait',
    class: class extends Trait {
      useTechnique(technique: Technique, target: Player, source: Player): void {
        if (technique.ingredients.includes(DieFace.Blowgun)) {
          let song = source.statusEffects.find(modifier => modifier.type === 'song') as Song;
          if (song) {
            song.activate();
          }

          song = target.statusEffects.find(modifier => modifier.type === 'song') as Song;
          if (song) {
            song.activate();
          }
        }
      }
    }
  }, {
    type: 'arbelist',
    title: 'Arbelist',
    text: 'When gaining Ammo (Basic), instead gain Ammo (Heavy). When gaining Ammo (Heavy), instead gain Ammo (Silver)',
    icon: 'trait',
    class: class extends Trait {
      modifierAdd(type: string, stacks: number, params: ModifierParams): number {
        if (type == 'ammo' && params.ammoType == AmmoType.Blank) {
          params.ammoType = AmmoType.Fist;
        }
        if (type == 'ammo' && params.ammoType == AmmoType.Fist) {
          params.ammoType = AmmoType.Silver;
        }
        return stacks;
      }
    }
  }
];